
.input-wrapper {
    position: relative;
    width: 100%;
}

.transparent-button {
    position: absolute !important;
    right: 0;
    top: 0;
    background: transparent !important;
    border: none !important;
}

.p-float-label label{
    left: 10px !important;
}

.transparent-button:hover {
    background: transparent !important;
}

.transparent-button .pi {
    color: #495057;
}