.policy-page {
    font-family: Arial, sans-serif;
    margin: 20px;
}

.policy-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 798px;
    margin: 0 auto 20px auto;
}

.logo {
    width: 50px;
    height: 50px;
    margin-right: 30px;
}

.policy-content {
    line-height: 1.6;
    width: 100%;
    max-width: 798px;
    margin: 0 auto;
}

.policy-content h2, .policy-content h3 {
    margin-top: 20px;
}

.policy-content ul {
    list-style-type: disc;
    margin-left: 20px;
}