body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 768px) {
  .mobile-dialog {
    max-width: 95%; /* Adjust maximum width for smaller screens */
    font-size: 10px; /* Adjust font size for better readability */
    /* Other styling adjustments as needed */
  }
}

@media only screen and (max-width: 768px){
  .verification-list-container {
    zoom: 80%; /* Adjust max-height for smaller screens */
    /* Other styling adjustments as needed */
  }
}

a:link { text-decoration: none; }

a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }
